:root {
  --sitecolor: #2c5691;
  --textcolor: #000;
  --titlecolor: #000;
  --linkcolor: #000;
  --linkcolor-hov: #90814e;
  --headerHeight: 140px;
  --gridGap: 25px;
  --easing: cubic-bezier(0.76, 0, 0.24, 1);
}
.button {
  font-size: clamp(0.9375rem, 0vw + 0.9375rem, 0.9375rem);
  line-height: clamp(1.5rem, 0vw + 1.5rem, 1.5rem);
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  border: none;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.01em;
  padding-right: 20px;
  text-decoration: none;
  color: var(--linkcolor);
  position: relative;
}
.button:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 0;
  width: 20px;
  height: 10px;
  mask-size: 11px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-black.svg);
  background-color: var(--linkcolor);
  transition: all 0.4s var(--easing);
}
.button:hover,
.button:focus {
  color: var(--linkcolor-hov);
}
.button:hover:after,
.button:focus:after {
  background-color: var(--linkcolor-hov);
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.navigation div.navi {
  float: left;
}
.navigation div.navi > .item {
  float: left;
}
.navigation div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation div.sub1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.navigation div.sub1 > .item {
  margin-right: 34px;
  position: relative;
}
.navigation div.sub1 > .item.exit {
  margin-right: 0;
}
.navigation div.sub1 > .item:hover > .menu,
.navigation div.sub1 > .item:focus > .menu {
  background-size: 100% 1px;
}
.navigation div.sub1 > .item > .menu {
  font-size: 18px;
  line-height: 1.66666667;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  background-size: 0 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #000, #000);
  transition: all 0.24s;
}
.navigation div.sub1 > .item > .menu.path {
  background-size: 100% 1px;
  font-weight: 600;
}
div.sub2 {
  position: absolute;
  left: 50%;
  top: 100%;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #000;
  transform: translateX(-50%);
  text-align: center;
  padding: 20px 30px;
  display: none;
  flex-direction: column;
  align-items: center;
}
div.sub2 > .item {
  margin-top: 10px;
}
div.sub2 > .item.cb-navi-target-naviElementOff {
  display: none;
}
div.sub2 > .item.init {
  margin-top: 0;
}
div.sub2 > .item > .menu {
  white-space: nowrap;
  font-size: 15px;
  line-height: 1.6;
  text-transform: uppercase;
  text-decoration: none;
  background-size: 0 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #000, #000);
  transition: all 0.24s;
  color: #000;
}
div.sub2 > .item > .menu:hover,
div.sub2 > .item > .menu:focus {
  background-size: 100% 1px;
}
div.sub2 > .item > .menu.path {
  background-size: 100% 1px;
  font-weight: 600;
}
.navigation div.sub1 > .item:hover div.sub2 {
  display: flex;
}
.area--one {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 0 var(--gridGap);
}
.area--one .unit {
  grid-column: span 12;
}
.area--one .unitOne--1-1-indent {
  padding-inline: 23.92857143%;
  box-sizing: border-box;
}
.area--one .unitOne--1-2 {
  grid-column: span 6;
}
.area--one .unitOne--1-3 {
  grid-column: span 4;
}
/*# sourceMappingURL=./screen-large.css.map */